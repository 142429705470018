<template>
  <div>
    <div v-if="! isModal" class="level is-variable bd-klmn-columns is-1 is-marginless">
      <div class="level-left">
        <b-button
          class="filter-button is-hidden-mobile"
          size="is-circle"
          icon-left="filter-remove"
          @click="clearFilter"
        />
        <div class="filter-tags">
          <b-tag
            v-for="(value, index) in filtered"
            :key="index"
            type="is-purble"
            closable
            @close="removeFilter(value)"
          >
            {{ $t('filter.' + value) }}
          </b-tag>
        </div>
      </div>
      <div class="level-right">
        <slot name="header-right" />
      </div>
    </div>
    <b-table
      :data="cars"
      :loading="isLoading"

      :current-page.sync="pagination.page"
      :total="pagination.total"
      :per-page="pagination.perPage"

      :default-sort-direction="sort.defaultOrder"
      :default-sort="[sort.field, sort.order]"

      striped
      sticky-header
      paginated
      hoverable
      backend-sorting
      backend-pagination

      class="no-wrap cars-table"

      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column v-slot="props" custom-key="actions" class="is-actions-cell" sticky>
        <b-button
          outlined
          size="is-small"
          class="button-purble btn-details"
          icon-left="arrow-right-bold"
          tag="router-link"
          :to="{ name: 'cars.edit', params: { id: props.row.id }}"
        >
          {{ $t('edit') }}
        </b-button>
      </b-table-column>

      <b-table-column label="Plaka" field="plate" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="filter.plate" :placeholder="$t('plateSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <span class="tag is-abbr-like">
            {{ props.row.plate }}
          </span>
        </template>
      </b-table-column>

      <b-table-column :label="$t('brand')" field="brand" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="filter.brand" :placeholder="$t('brandSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          {{ props.row.brand }}
        </template>
      </b-table-column>

      <b-table-column :label="$t('model')" field="model" sortable>
        <template slot="header" slot-scope="{ column }">
          <b-dropdown aria-role="menu" class="search-dropdown" :close-on-click="false">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item aria-role="menu-item" :focusable="false">
              <b-input v-model="filter.model" :placeholder="$t('modelSearch')" size="is-small" autocomplete="off" type="search" />
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          {{ props.row.model }}
        </template>
      </b-table-column>

      <b-table-column :label="$t('filter.status')" custom-key="actions" class="is-actions-cell" centered sortable field="status">
        <template slot="header" slot-scope="{ column }">
          <b-dropdown v-model="filter.status" aria-role="list">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item
              v-for="(status, index) in statusFilters"
              :key="index"
              :value="status.value"
              aria-role="listitem"
            >
              <span>{{ status.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          <span class="tag" :class="props.row.ready ? 'is-success' : 'is-danger'">
            {{ $t(`itemStatus.${props.row.ready}`) }}
          </span>
        </template>
      </b-table-column>

      <b-table-column v-slot="props" :label="$t('seats')" field="seats" centered sortable>
        <span v-if="props.row.seats" class="tag is-abbr-like">
          {{ props.row.seats }}
        </span>
      </b-table-column>

      <b-table-column :label="$t('filter.owner')" custom-key="actions" class="is-actions-cell" centered sortable field="owner">
        <template slot="header" slot-scope="{ column }">
          <b-dropdown v-model="filter.owner" aria-role="list">
            <b-icon slot="trigger" icon="magnify" class="search-icon pointer" custom-size="mdi-20px" />
            <b-dropdown-item value="Kurum" aria-role="listitem">
              <span>Kurum</span>
            </b-dropdown-item>
            <b-dropdown-item value="Diğer" aria-role="listitem">
              <span>Diğer</span>
            </b-dropdown-item>
          </b-dropdown>
          {{ column.label }}
        </template>
        <template v-slot="props">
          {{ props.row.owner }}
        </template>
      </b-table-column>

      <b-table-column v-slot="props" :label="$t('totalVoyage')" field="total_voyage" centered sortable>
        <span class="tag is-abbr-like">
          {{ props.row.total_voyage }}
        </span>
      </b-table-column>

      <b-table-column v-slot="props" :label="$t('totalDistance')" field="total_distance" centered sortable>
        <span class="tag is-abbr-like">
          {{ props.row.total_distance }}
        </span>
      </b-table-column>

      <b-table-column v-slot="props" :label="$t('totalTime')" field="total_time" centered sortable>
        <span class="tag is-abbr-like">
          {{ props.row.total_time_min }}
        </span>
      </b-table-column>

      <template slot="bottom-left">
        <div v-if="pagination.total > 0" class="has-text-left subtitle help">
          {{ $t('paginationShowing', [pagination.total.toLocaleString(), ((pagination.page * pagination.perPage) - pagination.perPage) + 1, ((pagination.page * pagination.perPage) > pagination.total ? pagination.total : (pagination.page * pagination.perPage)) ]) }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import $ from 'jquery'

export default {
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      pagination: {
        total: 0,
        page: 1,
        count: 0,
        perPage: 30,
      },
      sort: {
        defaultOrder: 'asc',
        field: 'plate',
        order: 'asc',
      },
      statusFilters: [
        {
          name: 'Tümü',
          value: '',
        },
        {
          name: 'Aktif',
          value: '1',
        },
        {
          name: 'Pasif',
          value: '0',
        },
      ],
      isLoading: true,
      inputTrigger: false,
      cars: [],
      filtered: [],
      filter: {},
    }
  },
  watch: {
    filter: {
      handler () {
        this.filterAction()
      },
      deep: true
    }
  },
  created () {
    this.filter = this.defaultFilters()
  },
  methods: {
    filterAction: debounce(function () {
      this.filterShow()
      this.getCars()
    }, 350),
    getCars () {
      const params = {
        sort_by: this.sort.field,
        sort_order: this.sort.order,
        page: this.pagination.page,
        ready: this.filter.status,
        plate: this.filter.plate,
        brand: this.filter.brand,
        model: this.filter.model,
        owner: this.filter.owner,
      }

      this.isLoading = true

      this.$http.get('list-cars', { params }).then(({ data }) => {
        if (data.meta) {
          this.pagination.total = data.meta.total
          this.pagination.perPage = data.meta.per_page
          this.pagination.page = data.meta.current_page
          this.pagination.count = data.meta.to
        } else {
          this.pagination.total = 0
        }

        this.cars = data.data

        this.isLoading = false

        this.$nextTick(() => {
          this.jqInputTrigger()
          $('.table-wrapper').scrollTop(0)
        })
      })
    },
    filterShow () {
      this.filtered = this.$_.keys(this.$_.omitBy(this.filter, this.$_.isEmpty))
    },
    clearFilter () {
      this.filter = this.defaultFilters()
    },
    defaultFilters () {
      return {
        status: '',
        plate: '',
        brand: '',
        model: '',
        owner: '',
      }
    },
    onSort (field, order) {
      this.pagination.page = 1
      this.sort.field = field
      this.sort.order = order

      this.getCars()
    },
    onPageChange (page) {
      this.pagination.page = page
      this.getCars()
    },
    jqInputTrigger () {
      if (this.inputTrigger) return;

      $('.dropdown-trigger').click(function (e) {
        e.stopPropagation()
        $(this).parent().find('input, select').first().focus()
      })
      $('.search-dropdown input').keypress(function (e) {
        if (e.which == '13') {
          $('body').trigger('click')
        }
      });
      $('.dropdown').click(function (e) {
        e.stopPropagation()
      })
      this.inputTrigger = true
    },
    removeFilter (filter) {
      const defaultFilters = this.defaultFilters()
      this.filter[filter] = defaultFilters[filter]
    },
  }
}
</script>
